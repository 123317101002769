import { Device } from "../class/Device";
import { GpsPosition } from "../class/GpsPosition";
import { LookupItem } from "../class/LookupItem";
import { Switchs } from "../class/Switch";
import { SmartHouseUser } from "../class/SmartHouseUser";
import { Log } from "../class/Log";
import { Terminal } from "../class/Cr_terminal";
import { Transfer } from "../class/Cr_transfer";
import { Asset } from "../class/Cr_asset";
import { Transaction } from "../class/Cr_transaction";
import { FlyTime } from "../class/flyTime";
import { UserAircraft } from "../class/UserAircraft";
import { UserAirport } from "../class/UserAirport";
import { Weather } from "../class/Weather";

const initState = {
  //For crypto transfer
  terminalList: new Array<Terminal>(),
  transferList: new Array<Transfer>(),
  assetList: new Array<Asset>(),
  transactionList: new Array<Transaction>(),
  lookupList: new Array<Array<LookupItem>>(),
  symbolCurrentPrice: 0,
  testMode: false,
  isTransSaved: false,



  //For smart house
  // flatList: new Array<Flat>(),
  // keyList: new Array<Key>(),
  // EntryList: new Array<Entry>(),

  flatList: new Array<any>(),
  keyList: new Array<any>(),
  entryList: new Array<any>(),
  isDoorCodeValide: true,
  isFlatKeySaved: false,
  isFlatKeyDeleted: false,
  flat: null,

  //for GPS trackers
  lookupTrackerList: new Array<LookupItem>(),
  gpsPositionList: new Array<GpsPosition>(),
  isGpsDeleted: false,
  isTrackerSaved: false,
  isTrackerDeleted: false,
  isTrackerUpdated: false,

  //For Log book
  isLogSaved: false,
  isLogDeleted: false,
  logBookList: new Array<Log>(),
  flyTime: new Array<FlyTime>(),
  userAircraftList: new Array<UserAircraft>(),
  userAirportList: new Array<UserAirport>(),
  airportTaf: new Array<any>(),
  airportMetar: new Array<any>(),
  chatGptReview: {},
}

const rootReducer = (state = initState, action: any) => {
  const newState = { ...state };
  switch (action.type) {

    //-------------SMART HOUSE------------------------
    case "SH_FLAT_LIST":
      newState.flatList = action.payload;
      return newState;

    case "SH_KEY_LIST":
      newState.keyList = action.payload;
      return newState;

    case "SH_ENTRY_LIST":
      newState.entryList = action.payload;
      return newState;

    case "SH_CHECK_CODE":
      console.log(action.payload);
      newState.isDoorCodeValide = action.payload;
      return newState;

    case "SH_SAVE_ENTRY":
      newState.entryList = action.payload;
      return newState;

    case "SH_SAVE_LABEL_SHOW":
      newState.isFlatKeySaved = action.payload;
      return newState;

    case "SH_UPDATE_ENTRY":
      newState.entryList = action.payload;
      return newState;

    case "SH_UPDATE_LABEL_SHOW":
      newState.isFlatKeySaved = action.payload;
      return newState;

    case "SH_DELETE_ENTRY":
      newState.entryList = action.payload;
      return newState;

    case "SH_DELETE_LABEL_SHOW":
      newState.isFlatKeyDeleted = action.payload;
      return newState;

    case "SH_OPEN_DOOR_WITH_CODE":
      newState.flat = action.payload;
      return newState;

    //-------------AVIATION LOG BOOK------------------------
    case "LOG_LIST":
      newState.logBookList = action.payload
      return newState;

    case "LOG_SAVED":
      newState.isLogSaved = true;
      newState.logBookList = action.payload;
      return newState;

    case "LOG_SAVED_HIDE_LABEL":
      newState.isLogSaved = false;
      return newState;

    case "LOG_UPDATED":
      newState.isLogSaved = true;
      newState.logBookList = action.payload
      return newState;

    case "LOG_UPDATED_HIDE_LABEL":
      newState.isLogSaved = false;
      return newState;

    case "LOOKUP_LIST":
      newState.lookupList = action.payload;
      return newState;

    case "LOG_DELETED":
      newState.isLogDeleted = true;
      newState.logBookList = action.payload
      return newState;

    case "LOG_DELETED_HIDE_LABEL":
      newState.isLogDeleted = false;
      return newState;

    case "FLYTIME":
      newState.flyTime = action.payload;
      return newState;

    case "USER_AIRCRAFT_LIST":
      newState.userAircraftList = action.payload;
      return newState;

    case "USER_AIRCRAFT_SAVED":
      newState.userAircraftList = action.payload;
      return newState;

    case "USER_AIRCRAFT_UPDATED":
      newState.userAircraftList = action.payload;
      return newState;

    case "USER_AIRCRAFT_DELETED":
      newState.userAircraftList = action.payload;
      return newState;

    case "USER_AIRPORT_LIST":
      newState.userAirportList = action.payload;
      return newState;

    case "USER_AIRPORT_UPDATED":
      newState.userAirportList = action.payload;
      return newState;

    case "USER_AIRPORT_DELETED":
      newState.userAirportList = action.payload;
      return newState;

    case "USER_AIRPORT_SAVED":
      newState.userAirportList = action.payload;
      return newState;

    case "WEATHER_GET_METAR":
      newState.airportMetar = action.payload;
      return newState;

    case "WEATHER_GET_TAF":
      newState.airportTaf = action.payload;
      return newState;

    case "WEATHER_CHATGPT_REVIEW":
      newState.chatGptReview = action.payload;
      return newState;


    //-------------OTHERS------------------------

    case "TRANSACTION_SAVED":
      newState.isTransSaved = true;
      newState.transferList = action.payload;
      return newState;

    case "TERMINAL_LIST":
      newState.terminalList = action.payload;
      return newState;

    case "TRANSFER_LIST":
      newState.transferList = action.payload;
      return newState;

    case "BINANCE_BALANCE":
      newState.assetList = action.payload;
      return newState;

    case "BINANCE_TRANS_HISTORY":
      newState.transactionList = action.payload;
      return newState;

    case "BINANCE_SYMBOL_PRICE":
      newState.symbolCurrentPrice = action.payload.price;
      return newState;

    case "GET_APP_MODE":
      newState.testMode = action.payload;
      return newState;

    case "UPDATE_APP_MODE":
      newState.testMode = action.payload;
      return newState;

    default:
      return state;
  }
}

export default rootReducer