import axios from 'axios';
import appsettings from '../appsettings';
const apiUrl = appsettings.serverBaseUrl + '/api/lookup/';  

// -------------Get lookup list ----------------
export const getLookupList = () =>{
  return async (dispatch  :any) =>{
    try{
      const res = await axios.get<any>(apiUrl + "GetLookupList/" , {headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}});
     
      return dispatch(getLookupSuccess(res.data));
    }
    catch (error) {
      throw (error)
    }
  }
 }
 
 export const getLookupSuccess = (data :any) => {
  return {
    type: "LOOKUP_LIST",
    payload: data
  }
 }