import React, { useState, useEffect } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './css/NavMenu.css'
import { useOktaAuth } from '@okta/okta-react';
// @ts-ignore
import logo from '../images/LogoNoTitle.png'

const NavMenu = () => {
    const [collapsed, setCollapsed] = useState<boolean>(true);
    const toggleNavbar = () => setCollapsed(!collapsed);
    const { oktaAuth, authState } = useOktaAuth(); 

    const loggingIn = async () => oktaAuth.signInWithRedirect({ originalUri: "/" });
    const loggingOut = async () => oktaAuth.signOut();

    const isAdmin = authState?.accessToken?.claims.sub == "sebastien.dubos@gmail.com" ? true : false;
    return (
        <header>
            <Navbar className="navbar navbar-expand-sm navbar-toggleable-sm box-shadow custom-navbar mb-3">
                <Container>
                    <div className="d-flex justify-content-between align-items-center w-100">
                        <NavbarBrand tag={Link} to="/">
                            <div style={{ float: 'left', marginBottom:'0px', paddingBottom:'0px' }}>
                                <img src={logo} className="logo" alt="#" style={{ height:'50px' }}/>
                            </div>
                            <div style={{ marginLeft: '10px', marginBottom:'0px', paddingBottom:'0px', width: '230px' }}>
                                <h2 className="m-0">My Univers</h2>
                            </div>
                        </NavbarBrand>
                        <NavbarToggler onClick={toggleNavbar} className="right mr-auto custom-toggler" />
                    </div>
                    <Collapse isOpen={!collapsed} navbar>
                        <ul className="navbar-nav flex-grow">
                            <NavItem>
                                <NavLink tag={Link} className="text-light" to="/">Home</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} className="text-light" to="/LogBookAircraft">LogBook</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} className="text-light" to="/SmartHouse">Smarthouse</NavLink>
                            </NavItem>
                            {isAdmin ?
                            <NavItem>
                                <NavLink tag={Link} className="text-light" to="/SmartHouseAdmin">Admin</NavLink>
                            </NavItem>
                            : ""}
                        </ul>
                        <div style={{ float: 'right', width:'100%' }}>
                            {authState?.isAuthenticated ? 
                                <div style={{ textAlign: 'right' }}>
                                   {authState?.accessToken?.claims.sub}  <button className="btn btn-success" onClick={loggingOut}><i className="fa fa-sign-out" aria-hidden="true"></i> Logout</button>
                                </div> 
                                : 
                                <div style={{ textAlign: 'right' }}> <button className="btn btn-success" onClick={loggingIn}><i className="fa fa-sign-in" aria-hidden="true"></i> Login</button></div>
                            }
                        </div>
                    </Collapse>
                </Container>
            </Navbar>
        </header>
    );
}

export default NavMenu;
