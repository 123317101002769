import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import * as actionCreator from '../actions/actions';
import ConfirmPopup from "./popup/ConfirmPopup";
import { withOktaAuth } from '@okta/okta-react';
import { Device } from '../class/Device';
import * as moment from 'moment';  //Format date
import appsettings from '../appsettings';
import Toggle from 'react-toggle';
import './css/Tracker.css';
import { Switchs } from '../class/Switch';
import { SmartHouseUser } from '../class/SmartHouseUser';

interface Props {
    oktaAuth: any;
    authState: any;
    navigation: any;
    flatTarget: any;
    getSwitchList(): void;
    updateSwitch(p: Switchs): void;
    getDoorSwitch(): Switchs;
    openDoor(p: any): void;
}

interface State {
    showWrongCodeAlert: boolean;
    icon1: boolean;
    icon2: boolean;
    icon3: boolean;
    icon4: boolean
}

//map the props of this class to the root redux state
const mapStateToProps = (state: any) => {
    return {
        //flatList: state.flatList,
        flatTarget: state.flat,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        //getFlatList: () => dispatch<any>(actionCreator.default.smartHouse.getFlatList()), //no tocken for this one because we use it unlogged
        openDoor: (code: any) => dispatch<any>(actionCreator.default.smartHouse.openDoorWithCode(code)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(class SmartHouse extends React.Component<Props, State> {
    static displayName = SmartHouse.name;
    public socket: any;
    private counter: number = 0;
    private mykey: any = "";

    constructor(props: any) {
        super(props);
        this.state = {
            showWrongCodeAlert: false,
            icon1: false,
            icon2: false,
            icon3: false,
            icon4: false
        };
    };

    async componentDidMount() {
        await this.props.getSwitchList();
        await this.props.getDoorSwitch();
    }

    handleSetAlarm = (theSwitch: any) => {
        theSwitch.smartHouseIsActivate = !theSwitch.smartHouseIsActivate;
        this.props.updateSwitch(theSwitch);
    }

    handleOpenDoor = (theSwitch: any) => {
        if (this.counter == 0) {
            this.mykey = "";
        }

        this.counter++;
        this.mykey = this.mykey + theSwitch;

        if (this.counter == 1) {
            this.setState({ icon1: true });
        }

        if (this.counter == 2) {
            this.setState({ icon2: true });
        }

        if (this.counter == 3) {
            this.setState({ icon3: true });
        }

        if (this.counter == 4) {
            this.setState({ icon4: true });
            this.props.openDoor(this.mykey);

            setTimeout(() => {
                this.setState({ showWrongCodeAlert: true });
            }, 500);
            setTimeout(() => {
                this.setState({ icon1: false, icon2: false, icon3: false, icon4: false, showWrongCodeAlert: false });
                this.counter = 0;
            }, 1500);
        }
    }

    render() {
        // let displayList = this.props.switchList.map((item, index) => (
        //     <tr key={index}>
        //         <td>{item.smartHouseId}</td>
        //         <td>{item.smartHouseName}</td>
        //         <td>{item.smartHouseDescription}</td>
        //         <td>
        //             <Toggle style={{ height: 10 }}
        //                 id='cheese-status'
        //                 defaultChecked={item.smartHouseIsClosed}
        //                 onChange={() => this.handleSetAlarm(item)}
        //             />
        //         </td>
        //     </tr>
        // )
        // );
        console.log(this.props.flatTarget);

        return (

            <div>
                <div>
                    <div className="row">
                    {!this.props.flatTarget?.flatIsDoorOpen && !this.state.showWrongCodeAlert && <div className="alert alert-warning" role="alert"><i className="fa fa-lock"></i> Locked </div>}
                    {this.props.flatTarget?.flatIsDoorOpen && <div className="alert alert-success" role="alert"><i className="fa fa-unlock"></i> Unlocked </div>}
                    {this.state.showWrongCodeAlert && !this.props.flatTarget && <div className="alert alert-danger" role="alert"> Wrong code!</div>}
                    </div>
                    <div className="row" >
                        <div className='col-4'>
                            &nbsp;{this.state.icon1 ? <i className="fa fa-star" style={{ color: '#CC6600' }} ></i> : null}
                            {this.state.icon2 ? <i className="fa fa-star" style={{ color: '#CC6600' }} ></i> : null}
                            {this.state.icon3 ? <i className="fa fa-star" style={{ color: '#CC6600' }} ></i> : null}
                            {this.state.icon4 ? <i className="fa fa-star" style={{ color: '#CC6600' }} ></i> : null}
                        </div>
                    </div>
                    <br />
                    <div className="row " >
                        <div className="btn-group" role="group" aria-label="Basic example">
                            <button type="button" className="btn btn-lg btn-outline-info rounded-0" style={{ maxHeight: 120, maxWidth: 80 }} onClick={() => this.handleOpenDoor(1)}>1</button>
                            <button type="button" className="btn btn-lg btn-outline-info rounded-0" style={{ maxHeight: 120, maxWidth: 80 }} onClick={() => this.handleOpenDoor(2)}>2</button>
                            <button type="button" className="btn btn-lg btn-outline-info rounded-0" style={{ maxHeight: 120, maxWidth: 80 }} onClick={() => this.handleOpenDoor(3)}>3</button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="btn-group" role="group" aria-label="Basic example">
                            <button type="button" className="btn btn-lg btn-outline-info rounded-0" style={{ maxHeight: 120, maxWidth: 80 }} onClick={() => this.handleOpenDoor(4)}>4</button>
                            <button type="button" className="btn btn-lg btn-outline-info rounded-0" style={{ maxHeight: 120, maxWidth: 80 }} onClick={() => this.handleOpenDoor(5)}>5</button>
                            <button type="button" className="btn btn-lg btn-outline-info rounded-0" style={{ maxHeight: 120, maxWidth: 80 }} onClick={() => this.handleOpenDoor(6)}>6</button>
                        </div>
                    </div>
                    <div className="row " >
                        <div className="btn-group" role="group" aria-label="Basic example">
                            <button type="button" className="btn btn-lg btn-outline-info rounded-0" style={{ maxHeight: 120, maxWidth: 80 }} onClick={() => this.handleOpenDoor(7)}>7</button>
                            <button type="button" className="btn btn-lg btn-outline-info rounded-0" style={{ maxHeight: 120, maxWidth: 80 }} onClick={() => this.handleOpenDoor(8)}>8</button>
                            <button type="button" className="btn btn-lg btn-outline-info rounded-0" style={{ maxHeight: 120, maxWidth: 80 }} onClick={() => this.handleOpenDoor(9)}>9</button>
                        </div>
                    </div>
                    <div className="row " >
                        <div className="btn-group" role="group" aria-label="Basic example">
                            <button type="button" className="btn btn-lg btn-outline-info rounded-0" style={{ maxHeight: 120, maxWidth: 80 }} onClick={() => this.handleOpenDoor(123)}>A</button>
                            <button type="button" className="btn btn-lg btn-outline-info rounded-0" style={{ maxHeight: 120, maxWidth: 80 }} onClick={() => this.handleOpenDoor(0)}>0</button>
                            <button type="button" className="btn btn-lg btn-outline-info rounded-0" style={{ maxHeight: 120, maxWidth: 80 }} onClick={() => this.handleOpenDoor(123)}>B</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}));