// import * as user from './userActions';
// import * as tracker from './trackerActions';
// import * as gps from './gpsActions';
import * as smartHouse from './smartHouseActions';
// import * as crypto from './cryptoActions';
import * as lookup from './lookupActions';
import * as logbook from './logBookActions';
import * as user from './userActions';
import * as userAircraft from './userAircraftActions';
import * as userAirport from './userAirportActions';
import * as weather from './weatherActions';
 
export default {logbook, lookup, user, userAircraft, userAirport, weather, smartHouse}
// export default {user, tracker, gps, smartHouse, crypto, logbook, lookup}