export enum Page {
    Home,
    Login,
    Logout,
    Tracker,
    About,
    Contact,
}

export enum MyLookup {
    AircraftList = 0,
    AirportList = 1,
    FLatList = 2,
    KeyList = 3,
    CurrencyList = 4,
    SymbolList = 5,
   
}