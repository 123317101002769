import * as React from 'react';
import { Modal, Button } from "react-bootstrap";
import { connect } from 'react-redux';
import * as actionCreator from '../../actions/actions';
import { Dispatch } from 'redux';
import Select from 'react-select';
import { MyLookup } from '../../class/Enums';
import { LookupItem } from '../../class/LookupItem';

interface State {
    entryId: any;
    entryName: any;
    entryCheckIn: any;
    entryCheckOut: any;
    flat: any;
    key: any;
    entryCode: any;
    //isDoorCodeValid: any;
}

interface Props {
    popupTitle: string,
    selectedEntry: any,
    token: any,
    show: boolean,
    isDoorCodeValide: boolean,
    isDoorCodeValideRedux: boolean,
    flatLookupList: any;
    keyLookupList  :any;
    hide(error: string): void,
    saveEntry(token: any, entry: any): void;
    updateEntry(token: any, entry: any): void;
    checkCode(token: any, code: any): void;
    getLookupList(): any;
}

//map the props of this class to the root redux state
const mapStateToProps = (state: any) => {
    return {
        entryId: state.entryId,
        isDoorCodeValideRedux: Boolean(state.isDoorCodeValide),
        flatLookupList: state.lookupList[MyLookup.FLatList],
        keyLookupList: state.lookupList[MyLookup.KeyList],
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        saveEntry: (token: any, entry: any) => dispatch<any>(actionCreator.default.smartHouse.saveEntry(token, entry)),
        updateEntry: (token: any, entry: any) => dispatch<any>(actionCreator.default.smartHouse.updateEntry(token, entry)),
        checkCode: (token: any, entry: any) => dispatch<any>(actionCreator.default.smartHouse.checkCode(token, entry)),
        getLookupList: () => dispatch<any>(actionCreator.default.lookup.getLookupList()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(class SmartHouseEntryPopup extends React.Component<Props, State> {
    private socket: any;

    constructor(props: any) {
        super(props)
        this.state = {
            entryId: 0,
            entryName: '',
            entryCheckIn: '',
            entryCode: '',
            entryCheckOut: '',
            flat : '',
            key : ''
        };
    }

    componentDidMount() {
        this.props.getLookupList();
    }

    componentDidUpdate(prevProps: any) {
       
        if (this.props !== prevProps && this.props.selectedEntry) {
            console.log(this.props.selectedEntry);
            this.setState({
                entryId: this.props.selectedEntry.entryId,
                entryName: this.props.selectedEntry.entryName,
                entryCheckIn: this.props.selectedEntry.entryCheckIn,
                entryCheckOut: this.props.selectedEntry.entryCheckOut,
                entryCode: this.props.selectedEntry.entryCode,
                key: { label: this.props.selectedEntry.key ? this.props.selectedEntry.key.keyId + " | " + this.props.selectedEntry.key.keyDescription : null, value: this.props.selectedEntry.key ? this.props.selectedEntry.key.keyId : null },
                flat: { label: this.props.selectedEntry.flat ? this.props.selectedEntry.flat.flatName : null, value: this.props.selectedEntry.flat ? this.props.selectedEntry.flat.flatId : null },
            })
        }
    }

    handleChange = (e: any) => {
        this.setState({
            [e.target.id]: e.target.value
        } as any);

        if (e.target.id == 'entryCode' && e.target.value.length == 4) {
            let newEntry: any;
            newEntry = { entryCode: e.target.value };
            this.props.checkCode(this.props.token, newEntry);
        }
    }

    handleFlatChange = (e: any) => {
        this.setState({
            flat: e
        });
    }

    handleKeyChange = (e: any) => {
        this.setState({
            key: e
        });
    }

    handleSaveEntry = (e: any) => {
        e.preventDefault();
        var myEntry: any = ({
            entryId: this.state.entryId,
            entryCode: this.state.entryCode,
            entryName: this.state.entryName,
            flatId : this.state.flat.value,
            keyId : this.state.key.value,
            entryCheckIn: this.state.entryCheckIn,
            entryCheckOut: this.state.entryCheckOut
        });
        if (myEntry.entryId != undefined) {
            this.props.updateEntry(this.props.token, myEntry);
         }
         else {
            this.props.saveEntry(this.props.token, myEntry);
         }
        
        this.props.hide("");
    }

    generateRandomCode = () => {
        let entryCode = Math.floor(1000 + Math.random() * 9000)
        this.setState({
            entryCode: entryCode,
        })
        let newEntry: any;
        newEntry = { entryCode: entryCode };
        this.props.checkCode(this.props.token, newEntry);
    }

    render() {
        return (
            <div>
                <Modal show={this.props.show} onHide={() => this.props.hide("")}>
                    <Modal.Header closeButton style={{ backgroundColor: '#2c2c2c' }}>
                        <Modal.Title >{this.props.popupTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ backgroundColor: '#2c2c2c' }}>
                        <form id="newEntryForm" className="form-signin" onSubmit={this.handleSaveEntry}>
                            <input id="EntryId" value={this.state.entryId} type="text" className="form-control" readOnly hidden></input>

                            <div className="form-label-group">
                                <label>For</label>
                                <input id="entryName" value={this.state.entryName} type="text" className="form-control" placeholder="Entry name" required onChange={this.handleChange}></input>
                            </div>

                            <label>Door code</label>  {!this.props.isDoorCodeValide || !this.props.isDoorCodeValideRedux &&<div style={{ float: "right", height: "32px", padding: "3px" }} className="alert alert-danger" role="alert"> Code already used!</div>}
                            <div className="input-group">
                                <input id="entryCode" value={this.state.entryCode} type="text" className="form-control" placeholder="Door code" required onChange={this.handleChange}></input>
                                <div className="input-group-append">
                                    <button className="btn btn-outline-secondary" type="button" onClick={() => this.generateRandomCode()}>Generate code</button>
                                </div>
                            </div>

                            <div className="form-label-group">
                                <label>Flat</label>
                                <Select id="flatId" placeholder="select a flat" onChange={this.handleFlatChange} options={this.props.flatLookupList} value={this.state.flat}
                                    styles={{
                                        control: (baseStyles) => ({
                                            ...baseStyles,
                                            padding: '0px',
                                            minHeight: '32px', // Ensure minimum height to avoid clipping content
                                        }),
                                        option: (provided) => ({
                                            ...provided,
                                            color: 'black', // or any other color that provides good contrast
                                        }),
                                    }}
                                />
                            </div>

                            <div className="form-label-group">
                                <label>Key</label>
                                <Select id="flatId" placeholder="select a key" onChange={this.handleKeyChange} value={this.state.key} options={this.props.keyLookupList}
                                    styles={{
                                        control: (baseStyles) => ({
                                            ...baseStyles,
                                            padding: '0px',
                                            minHeight: '32px', // Ensure minimum height to avoid clipping content
                                        }),
                                        option: (provided) => ({
                                            ...provided,
                                            color: 'black', // or any other color that provides good contrast
                                        }),
                                    }}
                                />
                            </div>

                            <div className="form-label-group">
                                <label>Arrival date</label>
                                <input id="entryCheckIn" value={this.state.entryCheckIn} type="datetime-local" className="form-control" placeholder="Date" required onChange={this.handleChange}></input>
                            </div>

                            <div className="form-label-group">
                                <label>Departure date</label>
                                <input id="entryCheckOut" value={this.state.entryCheckOut} type="datetime-local" className="form-control" placeholder="Date" required onChange={this.handleChange}></input>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer style={{ backgroundColor: '#2c2c2c' }}>
                        <Button variant="secondary" onClick={() => this.props.hide("")}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit" form="newEntryForm" >
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
})